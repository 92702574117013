
.circle {
    display: inline-block;
    background-color: #FF8531;
    height: 15px;
    width: 15px;
    border-radius: 25px;
  }
  
  
  #ball-1 {
    animation: bounce 1s infinite;
  }
  
  #ball-2 {
    animation: bounce 1.1s infinite;
  
  }
  
  #ball-3 {
    animation: bounce 1.2s infinite;
  
  }
  
  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
  
    40% {
      transform: translateY(23px);
    }
  
    60% {
      transform: translateY(-25px);
    }
  
    80% {
      transform: translateY(0);
    }
  }