@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  /* display: none; */
}

body {
  -ms-overflow-style: none;  
  scrollbar-width: none; 
}

/* ::-webkit-scrollbar {
  width: 5px;
  height: 100px;
  cursor: grab;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
::-webkit-scrollbar-thumb {
  background: #FF8531; 
  
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #FF8531;
}
td{
 word-wrap: break-word;
 break-word: word-break;
 word-break: break-word;
}
input:checked ~ .dot.vend_act{
  transform: translateX(84%);
}
.view_candidate input:focus-visible{
 outline: none; 
}
textarea{
  resize: none;
}
.quill {
  border-top: 1px solid #ccc;
  height: 150px;
  overflow-y: scroll;
}
.ql-toolbar.ql-snow{
  display: none;
  background-color:lightgrey;
  

}
.addjd .ql-toolbar.ql-snow{
  display:block;
}
.addjd .quill{
  overflow: visible;
  /* height: 150px; */
  
}
.ql-editor{
  /* margin-top:100px; */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
input[type='checkbox'] {
  accent-color: #FF8531;
}
.no-scrollbar {
  -ms-overflow-style: none;  
  scrollbar-width: none; 
}
.custom-scollbar {
  
}
/* width */
::-webkit-scrollbar {
  width: 0px;
  height: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  /*box-shadow: inset 0 0 5px grey; */
  border-radius: 10px;
  background: #f1f1f1;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #a9a9a9; 
  border-radius: 10px;
}